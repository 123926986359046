<template>
  <div id="product">
    <Header/>
    <div class="other__narrow__banner">
      <!-- <img src="./../assets/pic/about__banner.png" alt=""> -->
      <img :src="'/tw/upload/' + pageImage"  alt="" />
      <div class="other__narrow__banner__title">
        <div class="title_container">
          <h4>{{pageTitle}}</h4>
        </div>
      </div>
    </div>

    <div v-for="a in item.prodData" class="other__ck__block img__two" v-bind:id="'p'+a.id" :key="a.id">
      <div class="container">
        <div v-if="a.parent_id !== 275" class="other__ck__title title__bar" >
          <h2 v-html="a.title"></h2>
        </div>
        <div class="other__ck__text">
          <p v-html="a.descript">
            </p>
        </div>
        <div class="product_pic_items">
          <div class="product_pic_items_pic-wrapper" v-for="b in item.productImage.filter(x=>x.parent_id == a.id)" :key="b.id">
            <img :src="'/tw/upload/' + b.image" alt="" />
          </div>
        </div>
        <div v-html="a.content">
        
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import { apiProductData } from "@/api/pages.js";
import Header from './../components/Header'
export default {
  name: "Product",
  components: {
    Header
  },
  data(){
    return{
      pageTitle:'',
      pageImage:'',
      hash: '',
      p:{},
      item:{}
    }
  },
  metaInfo: {
    meta:[
      {
        property: 'og:url',
        content: 'https://www.elevator.com.tw/tw#/'
      },
      {
        property: 'og:locale',
        content: 'zh_TW'
      },
      {
        property: 'og:type',
        content: 'website'
      },
      {
        property: 'og:description',
        content: 'Product相對於小縮圖而言，大縮圖能顯示的文字量是比較少的，所以圖片上的資訊更加重要'
      },
      {
        property: 'og:title',
        content: '大縮圖分享連結Product',
        vmid: 'og:title'
      },
      {
        property: 'og:image:type',
        content: 'image/png'
      },
      {
        property: 'og:image',
        content: 'https://github.com/dean9703111/open_graph_sample/blob/master/img/big_thumbnail.png?raw=true'
      },
      {
        property: 'og:image:width',
        content: '1400'
      },
      {
        property: 'og:image:height',
        content: '770'
      },
    ]
  },
  watch: {
    $route(to, from) {
      // react to route changes...
      console.log(to)
      console.log('reload')
      location.reload();
    }
  },
  created(){
    this.p = this.$route.params
  },
  mounted: async function () {
    console.log(this.$route.params)
    this.p = this.$route.params
    const res = await apiProductData({ptid:this.p.ptid,psid:this.p.psid});
    this.item = res.data;
    if(this.item.prodType[0].id === 176){
      this.pageTitle = this.item.prodType[0].title
      this.pageImage = this.item.prodType[0].image
    }else{
      this.pageTitle = this.item.prodType[0].title + ' - ' + this.item.prodSerial[0].title
      this.pageImage = this.item.prodType[0].image
    }
    const target = `p${this.p.pdid}`
    console.log(target)
    var interval = setInterval( () => {
      console.log('進入interval')
      var tar =  document.querySelector(`#${target}`)
      console.log(tar)
      if (tar){
        clearInterval(interval)
        console.log('進入true')
        setTimeout(() => {
          console.log('進入true settimeout')
          document.querySelector(`#${target}`).scrollIntoView({behavior: "smooth"})
        }, 500);
      } else {
        clearInterval(interval)
        console.log('target null')
        setTimeout(() => {
          document.querySelector('#product').scrollIntoView({behavior: "smooth"})
        }, 500);
      }
      
    }, 600);
  },
  methods: {
    // getData(){
      
    // }
  },
};
</script>
<style lang="css" scoped>
  .other__narrow__banner__title {
    position: absolute;
    width: 100%;
  }
  .other__narrow__banner .title_container {
    text-align: center;
  }
  .other__narrow__banner__title h4 {
    color: white;
    position: absolute;
    display: contents;
    font-size: 28px;
  }
  @media screen and (min-width:768px) {
    .other__narrow__banner .title_container {
    max-width: 1140px;
    margin: 0 auto;
    padding-left: 25px;
    text-align: left;
}
  }
</style>